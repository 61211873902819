import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intl';
import 'intl/locale-data/jsonp/en-GB';
import 'intl/locale-data/jsonp/en-US';
import 'intl/locale-data/jsonp/en';
import 'whatwg-fetch';

async function getIntl() {
  return Promise.all([
    await import(/* webpackChunkName: "intl" */ 'intl' as any),
    await import(
      /* webpackChunkName: "intl" */ 'intl/locale-data/jsonp/en.js' as any
    ),
  ]);
}

if (!window.Intl) {
  getIntl();
}
